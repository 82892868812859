import React, { useState } from "react";
import { Link, navigate, withPrefix } from "gatsby";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Router } from "@reach/router"
import Post from "../components/post";
import User from "../components/user"
import SideBarContainer from "./sideBarContainer";
import logo from "../../static/dinanyheter-logo.png";
import fbLogo from "../../static/f_logo_RGB-Grey_1024.png";
import DrawerButton from "../components/drawerButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faQuestionCircle, faHome, faHeart, faNewspaper, faInfoCircle, faDoorClosed, faFacebook, faUsers, faPlus, faBullhorn, faComment, faClock }
    from '@fortawesome/free-solid-svg-icons';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

import { useSelector, useDispatch } from 'react-redux';

import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/en';
import i18nMessagesEn from '../i18n/menu.en.json';
import i18nMessagesSv from '../i18n/menu.sv.json';
import { FormattedMessage } from 'react-intl';
import SignInComponent from './signInComponent'
import Button from 'react-bootstrap/Button';



const Layout = ({ title, children, location }) => {
    const plusMember = useSelector(state => state.plusMember);

    // Check which language is used in the url and display the correct menu labels and links for it.
    let selectedLang = 'sv';
    let langUrl = '/';

    const [expanded, setExpanded] = useState(false);
    let infoTitle = <><FontAwesomeIcon icon={faInfoCircle} /> Info</>;

    return (
        <PageWrapper location={location} lang={selectedLang} i18nMessages={selectedLang == 'sv' ? i18nMessagesSv : i18nMessagesEn}>

            <div className="siteWrapper">
                <Navbar variant="dark" expand="lg" fixed="top" expanded={expanded}>
                    <DrawerButton />

                    <Link to={selectedLang == 'sv' ? langUrl : '/'}><Navbar.Brand><img className="logo" src={logo} alt="Dinanyheter logo" /></Navbar.Brand></Link>

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="mr-auto">
                            <Button className="purpleBtn" size="sm" variant="info" onClick={() => navigate('/createPost')} >
                                Skapa
                            </Button>

                            <SignInComponent langUrl={'/sv'} />
                        </Nav>
                    </Navbar.Collapse>

                    <div className="empty" />
                </Navbar>
                <div className="contentWrapper"
                    style={{
                        marginLeft: `auto`,
                        marginRight: `auto`,
                    }}
                >

                    <Router basepath="/post">
                        <Post path="/:postTitle" />
                    </Router>

                    <Router basepath="/user">
                        <User path="/:username" />
                    </Router>

                    {children}

                    <SideBarContainer langUrl={langUrl} />

                </div>
            </div>
        </PageWrapper>
    )
}

export default Layout
